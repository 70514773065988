import React, { useReducer } from "react";
import CaseContext from "./CaseContext";
import CaseReducer from "./CaseReducer";
import axios from "axios";

import {
	ADD_ACTIONS,
	ADD_ACTIONS_ERROR,
	ADD_CASE,
	ADD_CASE_ERROR,
	ADD_PAYMENTS,
	ADD_PAYMENTS_ERROR,
	CLEAR_CASE_ERRORS,
	GET_ACTIONS,
	GET_ACTIONS_ERROR,
	GET_ACTION_TYPES,
	GET_ACTION_TYPES_ERROR,
	GET_ALL_CASES,
	GET_ALL_CASES_ERROR,
	GET_ALL_PAYMENTS,
	GET_ALL_PAYMENTS_ERROR,
	GET_CLAIM_TYPES,
	GET_CLAIM_TYPES_ERROR,
	GET_LAW_FIRMS,
	GET_LAW_FIRMS_ERROR,
	GET_PAYMENTS,
	GET_PAYMENTS_ERROR,
	GET_PAYMENT_TYPES,
	GET_SINGLE_CASES,
	GET_SINGLE_CASES_ERROR,
	SEARCH_ALL_CASES,
	SEARCH_ALL_CASES_ERROR,
} from "../types";

export type Case = {
	caseid: number;
	caseno: string;
	casedescription: string;
	lawfirm: string;
	lawfirmemail: string;
	claimtype: string;
	complainant: string;
	filingdate: string;
	createdby: string;
	defendingadvocate: string;
	overallverdict: string | null;
	attachments: string;
	createdon: string;
	createdat: string;
	lawfirmid: number;
	claimtypeid: number;
	amountsuedfor: number;
};

export type CaseUpdate = {
	actionid: number;
	caseid: number;
	caseno: string;
	createdon: string;
	createdat: string;
	actiondescription: string;
	attachments: string | null;
	username: string;
	actiontype: string;
	actiondate: string;
};

export type CasePayment = {
	paymentid: number;
	caseid: number;
	caseno: string;
	createdon: string;
	createdat: string;
	paymentdescription: string;
	amount: number;
	paymenttype: number;
	paidby: string;
	attachments: string | null;
	username: string;
	payingdate: string;
};

export type LawFirm = {
	lawfirmid: number;
	lawfirm: string;
	lawfirmemail: string;
};

export type ClaimType = {
	claimtypeid: number;
	claimtype: string;
};

const CaseState = ({ children }: { children: React.ReactNode }) => {
	const initialState = {
		getlawfirms: null,
		caseserror: null,
		getclaimtypes: null,
		addcases: null,
		getallcases: null,
		getsinglecase: null,
		addpayments: null,
		addactions: null,
		getactiontypes: null,
		getpaymenttypes: null,
		getactions: null,
		getpayments: null,
		getallpayments: null,
		searchallcases: null,
	};

	//   const server_url = process.env.REACT_APP_SERVER_DOMAIN;

	const [state, dispatch] = useReducer(CaseReducer, initialState);

	const server_url = process.env.REACT_APP_SERVER_DOMAIN;

	const clearCaseErrors = () => {
		dispatch({
			type: CLEAR_CASE_ERRORS,
		});
	};

	const getLawFirms = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}case/getlawfirms`, config);
			dispatch({
				type: GET_LAW_FIRMS,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err: any) {
			dispatch({
				type: GET_LAW_FIRMS_ERROR,
				payload: err,
			});
			// console.log(err: any);
		}
	};

	const getClaimTypes = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}case/getclaimtypes`, config);
			dispatch({
				type: GET_CLAIM_TYPES,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err: any) {
			dispatch({
				type: GET_CLAIM_TYPES_ERROR,
				payload: err,
			});
			// console.log(err: any);
		}
	};

	const AddCases = async (cases: any) => {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
				"Content-Type": "multipart/form-data",
			},
		};

		const formData = new FormData();
		// Append the table data to the FormData object

		formData.append("defendingadvocate", cases.defendingadvocate);
		formData.append("caseno", cases.caseno);
		formData.append("casedescription", cases.casedescription);
		formData.append("lawfirm", cases.lawfirm);
		formData.append("claimtype", cases.claimtype);
		formData.append("complainant", cases.complainant);
		formData.append("filingdate", cases.filingdate);
		cases.files.forEach((file: any) => {
			formData.append("files", file);
		});

		try {
			const res = await axios.post(`${server_url}case/addcases`, formData, config);
			dispatch({
				type: ADD_CASE,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: ADD_CASE_ERROR,
				payload: err.response.data,
			});
		}
	};

	const AddActions = async (cases: any) => {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
				"Content-Type": "multipart/form-data",
			},
		};

		const formData = new FormData();
		// Append the table data to the FormData object

		formData.append("caseid", cases.caseid);
		formData.append("actiontype", cases.actiontype);
		formData.append("actiondescription", cases.actiondescription);
		cases.files.forEach((file: any) => {
			formData.append("files", file);
		});

		try {
			const res = await axios.post(
				`${server_url}case/addactions`,
				formData,
				config
			);
			dispatch({
				type: ADD_ACTIONS,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: ADD_ACTIONS_ERROR,
				payload: err.response.data,
			});
		}
	};

	const AddPayments = async (cases: any) => {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
				"Content-Type": "multipart/form-data",
			},
		};

		const formData = new FormData();
		// Append the table data to the FormData object

		formData.append("caseid", cases.caseid);
		formData.append("paymentdescription", cases.paymentdescription);
		formData.append("amount", cases.amount);
		formData.append("paymenttype", cases.paymenttype);
		formData.append("paidby", cases.paidby);
		cases.files.forEach((file: any) => {
			formData.append("files", file);
		});

		try {
			const res = await axios.post(
				`${server_url}case/addpayments`,
				formData,
				config
			);
			dispatch({
				type: ADD_PAYMENTS,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: ADD_PAYMENTS_ERROR,
				payload: err.response.data,
			});
		}
	};

	const getPaymentTypes = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}case/getpaymenttypes`, config);
			dispatch({
				type: GET_PAYMENT_TYPES,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err: any) {
			dispatch({
				type: GET_PAYMENT_TYPES,
				payload: err,
			});
			// console.log(err: any);
		}
	};

	const getActionTypes = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}case/getactiontypes`, config);
			dispatch({
				type: GET_ACTION_TYPES,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err: any) {
			dispatch({
				type: GET_ACTION_TYPES_ERROR,
				payload: err,
			});
			// console.log(err: any);
		}
	};

	const getAllCases = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}case/getallcases`, config);
			dispatch({
				type: GET_ALL_CASES,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err: any) {
			dispatch({
				type: GET_ALL_CASES_ERROR,
				payload: err,
			});
			// console.log(err: any);
		}
	};

	const getPayments = async (caseid: number) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}case/getpayments/${caseid}`,
				config
			);
			dispatch({
				type: GET_PAYMENTS,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err: any) {
			dispatch({
				type: GET_PAYMENTS_ERROR,
				payload: err,
			});
			// console.log(err: any);
		}
	};

	const getAllPayments = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}case/getallpayments`, config);
			dispatch({
				type: GET_ALL_PAYMENTS,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err: any) {
			dispatch({
				type: GET_ALL_PAYMENTS_ERROR,
				payload: err,
			});
			// console.log(err: any);
		}
	};

	const getActions = async (caseid: number) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}case/getactions/${caseid}`,
				config
			);
			dispatch({
				type: GET_ACTIONS,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err: any) {
			dispatch({
				type: GET_ACTIONS_ERROR,
				payload: err,
			});
			// console.log(err: any);
		}
	};

	const getSingleCase = async (caseid: number) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}case/getsinglecase/${caseid}`,
				config
			);
			dispatch({
				type: GET_SINGLE_CASES,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err: any) {
			dispatch({
				type: GET_SINGLE_CASES_ERROR,
				payload: err,
			});
			// console.log(err: any);
		}
	};

	const SearchAllCases = async (searchlist: any) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};

		try {
			const res = await axios.post(
				`${server_url}case/searchallcases`,
				searchlist,
				config
			);
			dispatch({
				type: SEARCH_ALL_CASES,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: SEARCH_ALL_CASES_ERROR,
				payload: err.response.data,
			});
		}
	};

	return (
		<CaseContext.Provider
			value={{
				...state,
				getLawFirms,
				clearCaseErrors,
				getClaimTypes,
				AddCases,
				getAllCases,
				getSingleCase,
				getPaymentTypes,
				getActionTypes,
				AddActions,
				AddPayments,
				getPayments,
				getActions,
				getAllPayments,
				SearchAllCases,
			}}
		>
			{children}
		</CaseContext.Provider>
	);
};

export default CaseState;
