import AttachmentDetails from "@/components/AttachmentDetails";
import CaseActions from "@/components/CaseActions";
import CasePayments from "@/components/CasePayments";
import CaseUpdates from "@/components/CaseUpdates";
import { Skeleton } from "@/components/ui/skeleton";
import { convertToTimeFormat } from "@/constants";
import { UserContext } from "@/context/User/UserContext";
import { getSingleCase } from "@/services/Case.service";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useParams } from "react-router-dom";

const CaseDetails = () => {
	const { id } = useParams<{ id: string }>() as { id: string };
	const user = useContext(UserContext);

	const caseDetail = useQuery({
		queryKey: ["case", id],
		queryFn: () => getSingleCase(user?.accessToken!, parseInt(id)),
		enabled: !!user?.accessToken,
	});

	return (
		<>
			{caseDetail.isLoading ? (
				<CaseSkeleton />
			) : caseDetail.isError ? (
				<div className="align-centre">
					<h5 className="text-center">Error loading case details.</h5>
				</div>
			) : (
				caseDetail.data && (
					<>
						<div className="flex gap-3 items-end justify-between mb-4">
							<h1 className="font-bold text-4xl">CASE NO: {caseDetail.data.caseno}</h1>
						</div>
						<h2 className="uppercase text-gray-700 font-bold tracking-wider">
							Case Details
						</h2>
						<hr className="mb-4" />

						<div className="border-y my-3 border bg-white shadow-sm rounded-md mb-6">
							<div className="grid grid-cols-1 sm:grid-cols-2 px-4 py-2">
								<div className="flex gap-4">
									<div className="flex flex-col gap-2 text-zinc-500 font-light text-nowrap">
										<p>Case ID</p>
										<p>Case Type</p>
										<p>Plaintiff/Petitioner</p>
										<p>Representing Law Firm</p>
										<p>Amount Sued For</p>
										<p>Summary</p>
									</div>
									<div className="flex flex-col gap-2">
										<p>{caseDetail.data.caseid}</p>
										<p>{caseDetail.data.claimtype}</p>
										<p>{caseDetail.data.complainant}</p>
										<p>{caseDetail.data.lawfirm}</p>
										<p>KES: {caseDetail.data.amountsuedfor}</p>
										<p>{caseDetail.data.casedescription}</p>
									</div>
								</div>
								<div className="flex gap-4 pt-2 md:pt-0">
									<div className="flex flex-col gap-2 text-zinc-500 font-light">
										<p>Filing Date</p>
										<p>Added On</p>
										<p>Added By</p>
									</div>
									<div className="flex flex-col gap-2">
										<p>{caseDetail.data.filingdate}</p>
										<p>{convertToTimeFormat(caseDetail.data.createdat)}</p>
										<p>{caseDetail.data.createdby}</p>
									</div>
								</div>
							</div>
						</div>
						{caseDetail.data.attachments && caseDetail.data.attachments.length > 0 ? (
							<div className="mb-4">
								<h2 className="uppercase text-gray-700 font-bold tracking-wider">
									Case Attachments
								</h2>
								<hr className="mb-4" />
								<AttachmentDetails attachments={caseDetail.data.attachments} />
							</div>
						) : null}
						<CaseActions
							id={caseDetail.data.caseid}
							accessToken={user?.accessToken!}
						/>
						<CasePayments
							id={caseDetail.data.caseid}
							accessToken={user?.accessToken!}
						/>
					</>
				)
			)}
		</>
	);
};

export default CaseDetails;

const CaseSkeleton = () => {
	return (
		<>
			<Skeleton className="h-12 w-1/2 mb-6" />
			<div className="flex gap-4">
				<Skeleton className="h-8 w-36" />
				<Skeleton className="h-8 w-36" />
			</div>
			<div className="border-y grid grid-cols-1 sm:grid-cols-2 py-4 border-zinc-200 gap-4 my-4">
				{Array.from({ length: 10 }).map((_, i) => (
					<Skeleton key={i} className="w-full h-8" />
				))}
			</div>
		</>
	);
};
